import React from 'react';

import Layout from '../components/Layout';

const NotFoundPage = () => (
	<Layout
		categories={[]}
		page={{next : null, previous : null}}
		seo={{title : '404: Not Found'}}
	>
		<h1>Not Found</h1>
		<p>You just hit a route that doesn&#39;t exist... the sadness.</p>
	</Layout>
);

export default NotFoundPage;
